import './App.css'
import { useEffect } from 'react'
import { CssBaseline, createTheme, useColorScheme } from '@mui/material'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Dashboard from './routes/Dashboard/Dashboard';
import LoginPage from './routes/Login/LoginPage';
import ErrorPage from './routes/ErrorPage';
import HomePage from './routes/Home/HomePage';
import NuevaOportunidadPage from './routes/NuevaOportunidad/NuevaOportunidadPage';
import NuevaOportunidadMasivaPage from './routes/NuevaOportunidadMasiva/NuevaOportunidadMasivaPage';
import GestorOportunidadesPage from './routes/GestorOportunidades/GestorOportunidadesPage';
import CalendarioPage from './routes/Calendario/CalendarioPage';
import MaestroProveedoresPage from './routes/MaestroProveedores/MaestroProveedoresPage';
import MaestroClientesPage from './routes/MaestroClientes/MaestroClientesPage';
import ConfiguracionPage from './routes/Configuracion/ConfiguracionPage';
import AgregarProveedorPage from './routes/AgregarProveedor/AgregarProveedorPage';
import ConfiguracionCuenta from './routes/ConfiguracionCuenta/ConfiguracionCuenta';
import ConfiguracionPermisos from './routes/ConfiguracionPermisos/ConfiguracionPermisos';
import ConfiguracionMantenedores from './routes/ConfiguracionMantenedores/ConfiguracionMantenedores';
import ConfiguracionGrupos from './routes/ConfiguracionGrupos/ConfiguracionGrupos';
import AgregarClientePage from './routes/AgregarCliente/AgregarClientePage';
import VerDetalleMaestrosPage from './routes/VerDetalleMaestros/VerDetalleMaestrosPage';
import VerDetalleHomologosClientePage from './routes/VerDetalleHomologosCliente/VerDetalleHomologosClientePage';
import VerDetalleHomologosProveedorPage from './routes/VerDetalleHomologosProveedor/VerDetalleHomologosProveedorPage';
import ItemsLicitacion from './routes/ItemsLicitacion/ItemsLicitacionPage';
import VerDetalleOportunidadPage from './routes/VerDetalleOportunidad/VerDetalleOportunidadPage';
import ConfiguracionMantenedoresAuxItems from './routes/ConfiguracionMantenedoresAuxItems/ConfiguracionMantendoresAuxItems';
import VistaAgregada from './routes/VistaAgregada/VistaAgregada';
import ConfiguracionMantenedoresVersiones from './routes/ConfiguracionMantenedoresVersiones/ConfiguracionMantenedoresVersiones';
import ConfiguracionMantenedoresModelos from './routes/ConfiguracionMantenedoresModelos/ConfiguracionMantenedoresModelos';
import ServiallSnackbar from './components/ServiallSnackbar';
import { ThemeProvider } from '@mui/material';

/**
 * A React component which is the entry point for the entire application.
 * @typedef App
 * @returns The React component for the app.
 */

function App() {

  const alert_state = useSelector((state) => state.navigation.alert_state);
  const theme = useSelector((state) => state.settings.theme);
  const lang = useSelector((state) => state.settings.language);

  const breakpoints = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 480,
        tablet: 640,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  })
  const { i18n } = useTranslation();
  const { setMode } = useColorScheme();

  const router = createBrowserRouter([
    {
      path: "/",
      element: <LoginPage />,
      errorElement: <ErrorPage />
    },
    {
      path: "/dashboard",
      element: <Dashboard />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "home/",
          element: <HomePage />
        },
        {
          path: "nueva_oportunidad/",
          element: <NuevaOportunidadPage />
        },
        {
          path: "nueva_oportunidad_masiva/",
          element: <NuevaOportunidadMasivaPage />
        },
        {
          path: "gestor_oportunidades/",
          element: <GestorOportunidadesPage />
        },
        {
          path: "calendario/",
          element: <CalendarioPage />
        },
        {
          path: "maestro_proveedores/",
          element: <MaestroProveedoresPage />
        },
        {
          path: "maestro_clientes/",
          element: <MaestroClientesPage />
        },
        {
          path: "configuracion/",
          element: <ConfiguracionPage />,
          children: [
            {
              path: "cuenta/",
              element: <ConfiguracionCuenta />
            },
            {
              path: "permisos/",
              element: <ConfiguracionPermisos />
            },
            {
              path: "mantenedores/",
              element: <ConfiguracionMantenedores />
            },
            {
              path: "grupos/",
              element: <ConfiguracionGrupos />
            },
            {
              path: "mantenedores/aux_items",
              element: <ConfiguracionMantenedoresAuxItems />
            },
            {
              path: "mantenedores/versiones",
              element: <ConfiguracionMantenedoresVersiones />
            },
            {
              path: "mantenedores/modelos",
              element: <ConfiguracionMantenedoresModelos />
            }
          ]
        },
        {
          path: "nuevo_proveedor/",
          element: <AgregarProveedorPage />
        },
        {
          path: "nuevo_cliente/",
          element: <AgregarClientePage />
        },
        {
          path: "detalle_maestros_cliente/",
          element: <VerDetalleMaestrosPage variant="client" />
        },
        {
          path: "detalle_maestros_proveedor/",
          element: <VerDetalleMaestrosPage variant="provider" />
        },
        {
          path: "detalle_homologo_cliente/:client_id",
          element: <VerDetalleHomologosClientePage />
        },
        {
          path: "detalle_homologo_proveedor/:provider_id",
          element: <VerDetalleHomologosProveedorPage />
        },
        {
          path: "detalle_oportunidad/",
          element: <VerDetalleOportunidadPage />
        },
        {
          path: "items_licitacion/",
          element: <ItemsLicitacion />
        },
        {
          path: "vista_agregada/:licitation_id",
          element: <VistaAgregada />
        },

      ]
    }
  ])

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang])

  useEffect(() => {
    setMode(theme);
  }, [theme])


  /* Disable console log if DEBUG=false or undefined */
  useEffect(() => {
    if (!process.env.REACT_APP_DEBUG || process.env.REACT_APP_DEBUG.toLowerCase() === "false") {
      if (!window.console) window.console = {};
      console["log"] = function () { };
    }
  }, [])

  return (
    <>
      <ThemeProvider theme={breakpoints}>
        <CssBaseline />
        <RouterProvider router={router} />
        <ServiallSnackbar
          snackbarOpen={alert_state.open}
          severity={alert_state.severity}
          message={alert_state.message}
        />
      </ThemeProvider>
    </>
  )
}

// test for github actions 0.43

App.displayName = "App";

export default App