// This file handles translations
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

// English translations imports
import AgregarProveedorPageEN from "./locales/en/AgregarProveedorPage.json";
import AgregarClientePageEN from "./locales/en/AgregarClientePage.json";
import CalendarioPageEN from "./locales/en/CalendarioPage.json";
import ConfiguracionPageEN from "./locales/en/ConfiguracionPage.json";
import ConfiguracionCuentaEN from "./locales/en/ConfiguracionCuenta.json";
import ConfiguracionPermisosEN from "./locales/en/ConfiguracionPermisos.json";
import DashboardEN from "./locales/en/Dashboard.json";
import DetalleMaestroPageEN from "./locales/en/DetalleMaestroPage.json";
import GestorOportunidadesPageEN from "./locales/en/GestorOportunidadesPage.json";
import HomePageEN from "./locales/en/HomePage.json";
import LoginPageEN from "./locales/en/LoginPage.json";
import MaestroClientesPageEN from "./locales/en/MaestroClientesPage.json";
import MaestroProveedoresPageEN from "./locales/en/MaestroProveedoresPage.json";
import NuevaOportunidadPageEN from "./locales/en/NuevaOportunidadPage.json";
import VerDetallePageEN from "./locales/en/VerDetallePage.json";
import FiltrosEN from "./locales/en/Filtros.json";
import VistaAgregadaEN from "./locales/en/VistaAgregada.json";
import ItemsLicitationEN from "./locales/en/ItemsLicitation.json";
import AddAuxItemDialogEN from "./locales/en/AddAuxItemDialog.json";
import DetalleHomologoProveedorPageEN from "./locales/en/DetalleHomologoProveedorPage.json";
import DetalleHomologoClientePageEN from "./locales/en/DetalleHomologoClientePage.json";
import MantenedoresItemsAuxiliaresEN from "./locales/en/MantenedoresItemsAuxiliares.json";
import MantenedoresVersionesEN from "./locales/en/MantenedoresVersiones.json";
import MantenedoresMenuEN from "./locales/en/MantenedoresMenu.json";
import MantenedoresModelosEN from "./locales/en/MantenedoresModelos.json";
import CommonEN from "./locales/en/Common.json";
import ConfiguracionGruposEN from "./locales/en/ConfiguracionGrupos.json";


// Spanish translations imports
import AgregarProveedorPageES from "./locales/es/AgregarProveedorPage.json";
import AgregarClientePageES from "./locales/es/AgregarClientePage.json";
import CalendarioPageES from "./locales/es/CalendarioPage.json";
import ConfiguracionPageES from "./locales/es/ConfiguracionPage.json";
import ConfiguracionCuentaES from "./locales/es/ConfiguracionCuenta.json";
import ConfiguracionPermisosES from "./locales/es/ConfiguracionPermisos.json";
import DashboardES from "./locales/es/Dashboard.json";
import DetalleMaestroPageES from "./locales/es/DetalleMaestroPage.json";
import GestorOportunidadesPageES from "./locales/es/GestorOportunidadesPage.json";
import HomePageES from "./locales/es/HomePage.json";
import LoginPageES from "./locales/es/LoginPage.json";
import MaestroClientesPageES from "./locales/es/MaestroClientesPage.json";
import MaestroProveedoresPageES from "./locales/es/MaestroProveedoresPage.json";
import NuevaOportunidadPageES from "./locales/es/NuevaOportunidadPage.json";
import VerDetallePageES from "./locales/es/VerDetallePage.json";
import FiltrosES from "./locales/es/Filtros.json";
import VistaAgregadaES from "./locales/es/VistaAgregada.json";
import ItemsLicitationES from "./locales/es/ItemsLicitation.json";
import AddAuxItemDialogES from "./locales/es/AddAuxItemDialog.json";
import DetalleHomologoProveedorPageES from "./locales/es/DetalleHomologoProveedorPage.json";
import DetalleHomologoClientePageES from "./locales/es/DetalleHomologoClientePage.json";
import MantenedoresItemsAuxiliaresES from "./locales/es/MantenedoresItemsAuxiliares.json";
import MantenedoresVersionesES from "./locales/es/MantenedoresVersiones.json";
import MantenedoresMenuES from "./locales/es/MantenedoresMenu.json";
import MantenedoresModelosES from "./locales/es/MantenedoresModelos.json";
import CommonES from "./locales/es/Common.json";
import ConfiguracionGruposES from "./locales/es/ConfiguracionGrupos.json";

let resources = {
    en: {
        AgregarProveedorPage: AgregarProveedorPageEN,
        AgregarClientePage: AgregarClientePageEN,
        CalendarioPage: CalendarioPageEN,
        ConfiguracionPage: ConfiguracionPageEN,
        ConfiguracionCuenta: ConfiguracionCuentaEN,
        ConfiguracionPermisos: ConfiguracionPermisosEN,
        Dashboard: DashboardEN,
        DetalleMaestroPage: DetalleMaestroPageEN,
        GestorOportunidadesPage: GestorOportunidadesPageEN,
        HomePage: HomePageEN,
        LoginPage: LoginPageEN,
        MaestroClientesPage: MaestroClientesPageEN,
        MaestroProveedoresPage: MaestroProveedoresPageEN,
        NuevaOportunidadPage: NuevaOportunidadPageEN,
        VerDetallePage: VerDetallePageEN,
        Filtros: FiltrosEN,
        VistaAgregada: VistaAgregadaEN,
        ItemsLicitation: ItemsLicitationEN,
        AddAuxItemDialog: AddAuxItemDialogEN,
        DetalleHomologoProveedorPage: DetalleHomologoProveedorPageEN,
        DetalleHomologoClientePage: DetalleHomologoClientePageEN,
        MantenedoresItemsAuxiliares: MantenedoresItemsAuxiliaresEN,
        MantenedoresVersiones: MantenedoresVersionesEN,
        MantenedoresMenu: MantenedoresMenuEN,
        MantenedoresModelos: MantenedoresModelosEN,
        Common: CommonEN,
        ConfiguracionGrupos: ConfiguracionGruposEN

    },
    es: {
        AgregarProveedorPage: AgregarProveedorPageES,
        AgregarClientePage: AgregarClientePageES,
        CalendarioPage: CalendarioPageES,
        ConfiguracionPage: ConfiguracionPageES,
        ConfiguracionCuenta: ConfiguracionCuentaES,
        ConfiguracionPermisos: ConfiguracionPermisosES,
        Dashboard: DashboardES,
        DetalleMaestroPage: DetalleMaestroPageES,
        GestorOportunidadesPage: GestorOportunidadesPageES,
        HomePage: HomePageES,
        LoginPage: LoginPageES,
        MaestroClientesPage: MaestroClientesPageES,
        MaestroProveedoresPage: MaestroProveedoresPageES,
        NuevaOportunidadPage: NuevaOportunidadPageES,
        VerDetallePage: VerDetallePageES,
        Filtros: FiltrosES,
        VistaAgregada: VistaAgregadaES,
        ItemsLicitation: ItemsLicitationES,
        AddAuxItemDialog: AddAuxItemDialogES,
        DetalleHomologoProveedorPage: DetalleHomologoProveedorPageES,
        DetalleHomologoClientePage: DetalleHomologoClientePageES,
        MantenedoresItemsAuxiliares: MantenedoresItemsAuxiliaresES,
        MantenedoresVersiones: MantenedoresVersionesES,
        MantenedoresMenu: MantenedoresMenuES,
        MantenedoresModelos: MantenedoresModelosES,
        Common: CommonES,
        ConfiguracionGrupos: ConfiguracionGruposES
    }
}

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        fallbackLang: "en",
        resources: resources
    })